import {
  Banner,
  Box,
  CardInline,
  Container,
  Heading,
  Helmet,
  Interfaces,
  Layout,
  Link,
  PreStyledComponents,
  Quote,
  Row,
  Theme,
  YouTubeVideo,
  routesObject
} from "@life-without-barriers/react-components"

import { IGatsbyImageData } from "gatsby-plugin-image"
import React from "react"
import { Site } from "@life-without-barriers/gatsby-common"
import { graphql } from "gatsby"

const { GridWrapper } = PreStyledComponents
const { youthFull } = Theme

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    bannerImage: IGatsbyImageData
    nextStepLogoImage: { childImageSharp: IGatsbyImageData }
    afterCarePaper: Interfaces.FileProps
  }
}

const breadcrumbs = [
  routesObject.home,
  routesObject.service,
  {
    to: "/services/child-youth-and-family",
    text: "Child, youth and family",
    title: "Return to parent page"
  }
]

const AfterCarePage = ({
  location,
  data: {
    site: { siteMetadata },
    bannerImage,
    nextStepLogoImage
  }
}: Props) => {
  return (
    <div>
      <Helmet
        title={`After care - for young people leaving care | ${siteMetadata.title}`}
        description="Giving young people who are leaving care the support they need to build independent lives."
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
        image={bannerImage}
      />
      <Layout theme={youthFull}>
        <Banner
          title="After care"
          subTitle="Giving young people who are leaving care the support they need to build independent lives."
          image={bannerImage}
          breadcrumbs={breadcrumbs}
        ></Banner>
        <Container>
          <Row>
            <Box className="layout-readable">
              <Heading size={2}>For young people who are leaving care</Heading>
              <p>
                Life Without Barriers walk alongside young people to
                progressively build skills for independence. Our evidence-based{" "}
                <Link to="/our-approach/child-youth-and-family/care-creating-conditions-for-change/">
                  CARE Model
                </Link>{" "}
                reminds us to see the individual in the context of their
                environment and to structure developmental support at the right
                level so that young people can experience success. Our aim is
                that young people leave care connected to family and community
                with happy and rewarding lives ahead.
              </p>
              <p>
                Life Without Barriers became a signatory to the Home Stretch
                Campaign in 2017. We work in partnership with the Children in
                Care Collective to lift the age of leaving care in all states
                and territories to 21.
              </p>
              <Heading size={2} topMargin="standard">
                Leaving care can be a difficult transition.
              </Heading>
              <p>
                Our{" "}
                <Link to="/services/child-youth-and-family/youth-advocate-program/">
                  Youth Advocate Program
                </Link>{" "}
                (YAP) provides 6 months of intensive unconditional support to
                care leavers in NSW. Relationships between the matched Youth
                Advocates and the people they support are tailored to individual
                needs and can include:
              </p>
              <ul className="pl4">
                <li className="mb2">
                  Transition into suitable living arrangements
                </li>
                <li className="mb2">
                  Developing pre-employment skills and finding employment
                </li>
                <li className="mb2">Re-connecting with family and culture</li>
                <li className="mb2">
                  Building community connection and participation
                </li>
                <li className="mb2">
                  {" "}
                  Developing and improving independent living skills
                </li>
                <li className="mb2">Legal advice</li>
              </ul>
              <Quote size="large">
                &ldquo;It was nice to have someone there, especially towards the
                end when I was freaking out the most, to have that one
                continuous person that was there. Normally kids don&apos;t
                really have that sort of support. But that&apos;s why it&apos;s
                good for me, I&apos;m really happy at the moment because I have
                those supports.&rdquo;
              </Quote>
              <p>
                <Link to="https://nextstepplus.com.au/">Next Step Plus</Link> is
                a program run from offices across QLD to assist young people
                transitioning from care up to the age of 25. Next Step After
                Care is for young people who have been in out of home care and
                who need access to practical advice and support with things
                like:
              </p>
              <ul className="pl4">
                <li className="mb2">Managing money and entitlements</li>
                <li className="mb2">Housing and accommodation</li>
                <li className="mb2">Training, jobs and preparing a resume</li>
                <li className="mb2">Keeping safe, strong and healthy</li>
                <li className="mb2">
                  Relationships, including friends and family
                </li>
                <li className="mb2">Legal advice</li>
              </ul>

              <YouTubeVideo
                className="pt4"
                title="NEXT STEP After Care - Life Without Barriers"
                videoId="-DwOR4fxMGY"
              />
              <GridWrapper className="pv3 flex-ns flex-wrap-ns flex-m justify-center-m">
                <CardInline
                  href="https://nextstepaftercare.com.au"
                  image={nextStepLogoImage.childImageSharp}
                  title="Want to learn more about Next Step After Care?"
                  imageObjectFit="contain"
                  imageContainerClassName="pl4 pr4 pr3-l pt3 pb3-l"
                  copy="For more information visit their website or call 1800 NEXT STEP"
                  standAlone
                />
              </GridWrapper>

              <p>
                Next Step After Care services is delivered in partnership with{" "}
                <Link to="https://create.org.au/">CREATE Foundation</Link>,
                Uniting Care Community, YETI, South Burnett CTC and{" "}
                <Link to="https://www.ifys.com.au/">
                  Integrated Family Youth Services
                </Link>
                , with funding from the Queensland Government.
              </p>
              <p>
                In all states and territories, Life Without Barriers assists
                care leavers to access and tap into available local supports.
              </p>
            </Box>
          </Row>
        </Container>
      </Layout>
    </div>
  )
}

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    bannerImage: file(relativePath: { regex: "/banner-after-care2.jpg/" }) {
      childImageSharp {
        gatsbyImageData(width: 1200)
      }
    }
    nextStepLogoImage: file(
      relativePath: { regex: "/after-care-next-step-logo.png/" }
    ) {
      childImageSharp {
        gatsbyImageData(width: 800)
      }
    }
    afterCarePaper: allFile(filter: { name: { eq: "LWB-Leaving-Care-WEB" } }) {
      edges {
        node {
          publicURL
        }
      }
    }
  }
`

export default AfterCarePage
